
import { PropType, defineComponent } from "vue";
import IconComponent from '@/components/mols/icon/Icon.vue';
import { IconKey } from '@/components/mols/icon/iconset';

type TopBGPlayload = {
  mobile: string;
  desktop?: string;
}

export default defineComponent({
  name: "HeaderComponent",

  components: {
    IconComponent,
  },

  props: {
    topBg: {
      type: Object as PropType<TopBGPlayload>,
      default: null,
    },
    heading: {
      type: String,
      default: null,
    },
    headingSmall: {
      type: String,
      default: null,
    },
    avatar: {
      type: String,
      default: null,
    },
    firstname: {
      type: String,
      default: null,
    },
    lastname: {
      type: String,
      default: null,
    },
    back: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    return {
      IconKey,
    }
  },
});
