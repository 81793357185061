import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AgaView from '../views/AgaView.vue'
import BartekView from '../views/BartekView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/aga',
    name: 'aga',
    component: AgaView
  },
  {
    path: '/bartek',
    name: 'bartek',
    component: BartekView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
