import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height", "viewBox", "fill"]
const _hoisted_2 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.size,
    height: _ctx.size / (_ctx.iconObj.width / _ctx.iconObj.height),
    viewBox: _ctx.iconObj.viewbox ?? `0 0 ${_ctx.iconObj.width} ${_ctx.iconObj.height}`,
    fill: _ctx.fill || _ctx.iconObj.fill
  }, [
    _createElementVNode("path", {
      d: _ctx.iconObj.path
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}