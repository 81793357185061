
import { defineComponent } from 'vue';
import IconComponent from '@/components/mols/icon/Icon.vue';
import HeaderComponent from '@/components/mols/header/Header.vue';
import { IconKey } from '@/components/mols/icon/iconset'

export default defineComponent({
  name: 'HomeView',

  components: {
    IconComponent,
    HeaderComponent,
  },

  setup() {
    return {
      IconKey,
    }
  },
})
