
  import { defineComponent } from "vue";
  import { iconSet, IconSet } from "./iconset";

  export default defineComponent({
    name: "IconComponent",

    props: {
      icon: {
        type: String as () => keyof IconSet,
        required: true,
      },
      size: {
        type: Number,
        default: 10,
      },
      fill: {
        type: String,
        default: undefined,
      },
    },

    setup(props) {
      return {
        iconObj: iconSet[props.icon],
      }
    },
  });
  