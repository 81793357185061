import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16a4ce80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top" }
const _hoisted_2 = { class: "-bg" }
const _hoisted_3 = ["srcset"]
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "-avatar"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 2,
  class: "-details"
}
const _hoisted_10 = { class: "-details-name" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_component = _resolveComponent("icon-component")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    (_ctx.back)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.back,
          class: "-back"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon_component, {
              fill: "white",
              size: 36,
              icon: _ctx.IconKey.ARROW_BACK
            }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["to"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("picture", null, [
        (_ctx.topBg.desktop)
          ? (_openBlock(), _createElementBlock("source", {
              key: 0,
              srcset: _ctx.topBg.desktop,
              media: "(min-width: 768px)"
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        (_ctx.topBg)
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _ctx.topBg.mobile,
              loading: "lazy",
              alt: _ctx.firstname && _ctx.lastname ? `${_ctx.firstname} ${_ctx.lastname}` : undefined
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      (_ctx.heading)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_5, [
            _createElementVNode("span", null, _toDisplayString(_ctx.heading), 1),
            (_ctx.headingSmall)
              ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.headingSmall), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.avatar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("img", {
            src: _ctx.avatar,
            alt: ""
          }, null, 8, _hoisted_8)
        ]))
      : _createCommentVNode("", true),
    (_ctx.firstname || _ctx.lastname)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            (_ctx.firstname)
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.firstname), 1))
              : _createCommentVNode("", true),
            (_ctx.lastname)
              ? (_openBlock(), _createElementBlock("span", _hoisted_12, " " + _toDisplayString(_ctx.lastname), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}